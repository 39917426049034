import React, {useState,useContext} from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import successImg from '../assets/images/img-ok-window.svg';
import errorImg from '../assets/images/img-error-window.svg';

import {useSearchParams,useNavigate} from "react-router-dom";
 

function ModalInfo({toMarketPlace, ammount,type,text}) {

    return (
        <div style={{position:'absolute',zIndex:'999',width:'100%', background:'rgba(255,255,255,0.5)', height:'100%'}}>
            <div class="info__window"  style={{marginTop:'70px'}}>
                
                {
                    type === 'success' ? (
                        <div>
                             <img style={{marginTop:'34px'}}   src={successImg} alt=""/>
                            <p class="info__window-title">Успешно!</p>
                        </div>
                       
                    ) : (
                        <div>
                             <img style={{marginTop:'34px'}}   src={errorImg} alt=""/>
                        <p class="info__window-title">Произошла ошибка!</p>
                        </div>
                        
                    )
                }
                <p class="info__window-text">{text} </p>
                
                <button  onClick={() => toMarketPlace()}  class="btn-black" style={{fontSize:'14px'}}  >Вернуться</button>
            </div>
        </div>
    );
}

export default ModalInfo;
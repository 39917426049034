import React, { useState, useContext } from 'react';
import creditCard from '../assets/images/Credit-card.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import Wallet from '../assets/images/Wallet.svg';
import { useSearchParams, useNavigate } from "react-router-dom";
import { UserContext } from '../App';


function Crypto ({ setNetwork }) {
    const [cryptoW, setCryptoW] = useState(false);
    const navigate = useNavigate();
    const [backLoading, setBackLoading] = useState(false);
    const [withdrawValue, setWithdrawValue] = useState('');
    const [searchParams] = useSearchParams()
    const token = searchParams.get('token')
    const { user, setUser } = useContext(UserContext);
    const networks = [
        { 'name': 'Binance Smart Chain', 'slug': 'bep20', 'img': './images/arrow.svg' },
        { 'name': 'Tron TRC20', 'slug': 'trc20', 'img': './images/arrow.svg' },
        { 'name': 'ETH', 'slug': 'eth', 'img': './images/arrow.svg' },
    ]

    function back () {
        if (cryptoW) navigate(`/?token=${token}`);
        else navigate(`/?token=${token}`);
    }


    return (
        <div style={{ position: 'absolute', zIndex: '999', width: '100%', background: 'rgba(255,255,255,0.5)', height: '100%' }}>
            <div className="chose__connection" style={{ marginTop: '70px' }}>
                <p className="screen__tetle">Выберите сеть</p>
                {
                    networks.map((netwok) => {
                        return (
                            <div key={netwok.slug} className="block__chose_item border-gradient-40" onClick={() => setNetwork(netwok)} >
                                <div>{netwok.name}</div>
                                {/* <img style={{transform: 'rotate(180deg)'}}   src="./images/arrow.svg" alt=""/> */}
                            </div>
                        )
                    })
                }

                <button onClick={() => setNetwork(networks[0])} className="btn-black" style={{ marginTop: '16px' }}>Вернуться</button>
            </div>
        </div>
    );
}

export default Crypto;

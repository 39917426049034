import React, {useState} from 'react';
import Tinkof from '../assets/images/logo-tinkoff.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Wallet from '../assets/images/Wallet.svg';
import Arrow from '../assets/images/arrow.svg';
import Net from '../assets/images/net.svg';
import ModalNetwork from '../components/ModalNetwork';
import ModalInfo from '../components/ModalInfo';
import {useSearchParams,useNavigate} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


function Crypto() {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [showAddress, setShowAddress] = useState(false);

  const [backLoading, setBackLoading] = useState(false);
  const [ValueSetted, setValueSetted] = useState(false);
  const [NetworkSetted, setNetworkSetted] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [WalletDict, setWallet] = useState({});
  const [Ammount, setAmmount] = useState('');
  const [errorModal, setErrorModal] = useState(false);
  const [responseText, setresponseText] = useState('');
  
  const [Network , setNetworkd] = useState({'name':'Binance Smart Chain','slug':'bep20' });
   const [searchParams] = useSearchParams()
   const token = searchParams.get('token')

   
   const setValue = (value) => {
    setValueSetted(true)
    setAmmount(value)
  }
  const setNetwork = (value) => {
    setNetworkSetted(true)
    setNetworkd(value)
    sendRequest()
  }

  function sendRequest() {
    setShowAddress(false)
    const data = {
        "asset":"usdt",
        "network":Network?.slug
    }
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': `${token}` },
        body: JSON.stringify(data)
    };
    
    fetch('https://app.pubhause.com/api/wallet/get-crypto-address',requestOptions)
      .then(response => {
         response.json().then(data => {
            console.log(data)
            setWallet(data.data)
            setShowAddress(true)
         })
      })
      .catch((err) => {
        console.log(err.message);
    });

  }


   function back() {
        navigate(`/?token=${token}`);
    }

   function toMarketPlace() {
      setBackLoading(true)

      setTimeout(
         function() {
            setBackLoading(false)
         }
         .bind(this),
         3000
     );
      window.location.href  = `https://app.pubhause.com/me/?token=${token}`;
    }

    return (
        
                <div className="App">
                    { !NetworkSetted ? (<ModalNetwork setNetwork={setNetwork} />): (null)}
                    { successModal ? (<ModalInfo toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'success'}/>): (null)}
                    { errorModal ? (<ModalInfo toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'error'}/>): (null)}
                       <div  style={{marginTop:'40px'}} className="chose__screen"  >
                        <p className="screen__tetle">Ваш крипто-кошелек
                           <span style={{margin:'10px'}} onClick={() => back()}> <img src={Arrow} alt=""/> </span>
                        </p>
                        <div className="block__chose">
                           
                            <p>Сеть и токен</p>
                            <div style={{display:'flex', gap:'10px'}}>
                                <div style={{flex: '0 0 70%'}}>
                                    <div className="block__chose_input"  onClick={() =>setNetworkSetted(false)}  >
                                        <div className="block__chose_input-img">
                                        <img   src={Net}  alt=""/>
                                        </div>
                                        <div className="delimiter"></div>
                                        <div className="block__chose_input-btn">
                                        <div className="block__chose_input-name">{Network.name}</div>
                                            <img style={{transform: 'rotate(270deg)'}} src={Arrow} alt=""/>
                                        </div>
                                    </div>
                                </div>

                                <div className="block__chose_input" >
                                    <div className="block__chose_input-btn">
                                    <div className="block__chose_input-name">USDT</div>
                                    </div>
                                </div>
                            </div>

                            {
                                showAddress? (
                                    <div>
                                        <p>Адрес</p>
                                        <CopyToClipboard text={WalletDict?.address}
                                            onCopy={() => { alert('скопировано')}}
                                            >
                                        <div className="block__chose_input">
                                            <div className="block__chose_input-img">
                                            <img src={Net}  alt=""/>
                                            </div>
                                            <div className="delimiter"></div>
                                            <div className="block__chose_input-btn">
                                            <div className="block__chose_input-name" style={{fontSize:14, wordBreak:'break-all'}}><strong>{WalletDict?.address}</strong></div>
                                            </div>
                                        </div>
                                        </CopyToClipboard>
                                        
                                        <div className="" style={{width:'100%',justifyContent:'center',textAlign:'center'}}>
                                            <img style={{alignSelf:'center'}} src={`https://app.pubhause.com${WalletDict?.qr_address}`} alt=""/>
                                        </div>

                                        
                                    </div>
                                ):(
                                    null
                                )
                            }
                            

                        </div>
                        {/* <div className="block__chose_money">
                            <p className="block__chose_money-text">Комиссия</p>
                            <p className="block__chose_money-money">300 &#8381;</p>
                        </div>
                        <div className="block__chose_money"  style={{marginTop: '5px'}} >
                            <p className="block__chose_money-text">Вы получите</p>
                            <p className="block__chose_money-money">700 $</p>
                        </div> */}
                        <button  onClick={() => toMarketPlace()}   className="btn-gradient" style={{marginBottom: '10px',marginTop: '20px'}}  >
                            Оплатил &#8195;
                            <img src={longArrow} alt=""/>
                        </button>
                        <button  onClick={() => toMarketPlace()}  className="btn-black"  style={{marginBottom: '26px'}} >Вернуться на главную</button>
                    </div>
                </div>
    );
}

export default Crypto;

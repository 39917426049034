import React, { useEffect, useRef, useState } from 'react';
import Tinkof from '../assets/images/logo-tinkoff.svg';
import longArrow from '../assets/images/long-arrow.svg';
import Telephone from '../assets/images/telephone.svg';
import Arrow from '../assets/images/arrow.svg';
import FlagRu from '../assets/images/flag-rus.svg';
import ModalBank from '../components/ModalBank';
import { useSearchParams, useNavigate } from "react-router-dom";
import ModalInfo from '../components/ModalInfo';
import Timer from '../components/Timer';


function Fiat () {
  const [cryptoW, setCryptoW] = useState(false);
  const navigate = useNavigate();
  const [backLoading, setBackLoading] = useState(false);
  const [ValueSetted, setValueSetted] = useState(false);
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const uid = searchParams.get('uid')
  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [Ammount, setAmmount] = useState('');
  const [responseText, setresponseText] = useState('');

  const [bankSetted, setBankSetted] = useState(false);
  const [isModalBankOpen, setModalBankOpen] = useState(false);
  const [bankValue, setBankValue] = useState();
  const [phone, sePhone] = useState('');
  // list of banks from api
  const [banks, setBanks] = useState();
  const [card, setCard] = useState('');
  const [merchant, setMerchant] = useState();
  const confirmCheckbox = useRef(null);

  useEffect(() => {
    fetch(`https://app.pubhause.com/api/p2p/payment-methods`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` }
    })
      .then(response => {
        response.json().then((data) => {
          setresponseText(data.message);
          if (response.status === 200) {
            setBanks(data.data);
            setBankValue(data.data[0]);
          } else {
            setErrorModal(true);
          }
        })
      })
      .catch(error => {
        console.log(error)
      })
  }, []);

  useEffect(() => {
    if (!merchant || (merchant && merchant.status === 'process')) return;

    const interval = setInterval(() => {
      fetch(`https://app.pubhause.com/api/p2p/set-paid?uid=${uid}`)
        .then(response => {
          response.json().then((data) => {
            setresponseText(data.message);
            if (data.data.status === 'approved') {
              clearInterval(interval);
              setSuccessModal(true);
            }
            if (data.data.status === 'rejected') {
              setErrorModal(true);
            }
          })
        })
        .catch(error => console.log(error));
    }, 1000);
    return () => clearInterval(interval);
  }, [merchant, uid]);

  const setValue = (value) => {
    setAmmount(value)
    setValueSetted(true)
  }
  async function sendRequest () {
    // if (phone.length < 10) {
    //   alert('проверьте номер телефона')
    //   return
    // }
    const data = {
      "value": Ammount,
      "id": bankValue.id,
      "phone": phone,
      "card": card,
      "type": 'card',
      "uid_payment": uid,
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` },
      body: JSON.stringify(data)
    };
    console.log(requestOptions)

    fetch('https://app.pubhause.com/api/p2p/sign-merchant', requestOptions)
      .then(response => {
        response.json().then(data => {
          setresponseText(data.message);
          if (response.status === 200) {
            setMerchant(data.data);
          }
          else {
            setErrorModal(true)
          }

        })
      })
      .catch((err) => {
        console.log(err.message);
      });

  }

  const setBank = (value) => {
    setBankSetted(true)
    setBankValue(value)
  }
  function cc_format (value) {
    const v = value
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(" ") : value;
  }

  function getFormattedPhoneNum (input) {
    input = input.replace("+7 ", "");
    let output = "+7 (";
    input.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
      if (g1.length) {
        output += g1;
        if (g1.length === 3) {
          output += ")";
          if (g2.length) {
            output += " " + g2;
            if (g2.length === 3) {
              output += " - ";
              if (g3.length) {
                output += g3;
              }
            }
          }
        }
      }
    }
    );
    return output;
  }


  function onPhoneChange (phone) {
    let newPhone = getFormattedPhoneNum(phone)
    console.log(newPhone)
    sePhone(newPhone)
  }
  function onCardChange (value) {
    let newCard = cc_format(value)
    console.log(value)
    setCard(newCard)
  }


  function toMarketPlace () {
    setBackLoading(true)

    setTimeout(
      function () {
        setBackLoading(false)
      }
      ,
      3000
    );
    window.location.href = `https://app.pubhause.com/me/?token=${token}`;
  }


  function onConfirm () {
    if (confirmCheckbox.current.checked) {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', 'Authorization': `${token}` },
      };

      return fetch(`https://app.pubhause.com/api/p2p/set-paid?uid=${uid}`, requestOptions)
        .then(response => {
          response.json().then(data => {
            setresponseText(data.message);
            if (response.status === 200) {
              setMerchant(data.data);
            }
            else {
              setErrorModal(true)
            }

          })
        })
        .catch((err) => {
          console.log(err.message);
        });
    }

    alert('подтвердите оплату');
  }

  return (
    <div className="App" >
      {isModalBankOpen ? (<ModalBank banks={banks} setBank={(bank) => {
        setBank(bank);
        setModalBankOpen(false);
      }} />) : (null)}
      {successModal ? (<ModalInfo toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'success'} />) : (null)}
      {errorModal ? (<ModalInfo toMarketPlace={toMarketPlace} ammount={Ammount} text={responseText} type={'error'} />) : (null)}
      {merchant === undefined ? <div style={{ marginTop: '60px' }} className="chose__screen">
        <p className="screen__tetle">Оплата картой
          <img onClick={() => toMarketPlace()} src={Arrow} alt="" />
        </p>
        <div className="block__chose">
          <p>Выберите вашу страну</p>
          <div className="block__chose_input">
            <div className="block__chose_input-img">
              <img src={FlagRu} alt="" />
            </div>
            <div className="delimiter"></div>
            <div className="block__chose_input-btn">
              <div className="block__chose_input-name">Россия</div>
              {/* <img style={{transform: 'rotate(270deg)'}} src={Arrow} alt=""/> */}
            </div>
          </div>
          {bankValue ? (
            <>
              <p>Выберите ваш банк</p>
              <div className="block__chose_input" onClick={() => setModalBankOpen(true)} >
                <div className="block__chose_input-img">
                  <img src={bankValue.logo} height={16} alt="" />
                </div>
                <div className="delimiter"></div>
                <div className="block__chose_input-btn">
                  <div className="block__chose_input-name">{bankValue.name}</div>
                  <img style={{ transform: 'rotate(270deg)' }} src={Arrow} alt="" />
                </div>
              </div></>
          ) : null}
          {/* <p>Введите ваш телефон</p>
          <div className="block__chose_input">
            <div className="block__chose_input-img">
              <img src={Telephone} alt="" />
            </div>
            <div className="delimiter"></div>

            <input id="phone__number" type="tel" maxLength="50"
              autoFocus value={phone}
              placeholder="+7 (915) 123 0000"
              onChange={e => onPhoneChange(e.target.value)} />
          </div> */}
{/* 
          {
            bankValue?.slug === 'sbp' ? (null) : (
              <div>
                <p>Номер карты </p>
                <div className="block__chose_input">
                  <input type="tel" id="card__number" maxLength="19"
                    onChange={e => onCardChange(e.target.value)}
                    placeholder="XXXX XXXX XXXX XXXX" value={card} />
                </div>
              </div>
            )
          } */}

        </div>
        {/* <p className="screen__text" style={{margin: '4px 0 40px'}} >«Вывод денежных средств осуществляется в течении 24 часов,
                            пожалуйста будьте внимательны
                            при вводе данных карты»
                        </p> */}
        <button className="btn-gradient" onClick={() => sendRequest()} style={{ marginBottom: '10px' }} >
          Оплатить
          {/* <img src={longArrow} alt="" /> */}
        </button>
        <button className="btn-black" onClick={() => toMarketPlace()} style={{ marginBottom: '26px' }} >Вернуться</button>
      </div> : null}
      {merchant && merchant.status === 'process' ?
        <div style={{ marginTop: '60px' }} className="chose__screen">
          <p className="screen__tetle" style={{ marginBottom: '48px' }}>Вы покупаете
            <img onClick={() => toMarketPlace()} src={Arrow} alt="" />
          </p>
          <div className="headphone__model border-gradient-40-2">
            {/* <div className="headphone__model-img" style={{ background: 'linear-gradient(180deg, #FDF7E9 0%, #CFC6AB 103.7%)' }}>
              <img src={} alt="" />
            </div> */}
            <div className="headphone__model-text">
              <p className="headphone__model-text-name">Пополнение</p>
              <p className="headphone__model-text-title">uid {merchant.uid}</p>
            </div>
            <div className="headphone__model-price">
              {merchant.value} ₽
            </div>
            {/* <div className="headphone__model-img" style={{ background: 'linear-gradient(180deg, #343537 0%, #434446 103.7%)' }}>
              <img src="./images/headphone-model-3.png" alt="" />
            </div>
            <div className="headphone__model-text">
              <p className="headphone__model-text-name">Модель 3</p>
              <p className="headphone__model-text-title">Новые наушники</p>
            </div>
            <div className="headphone__model-price">
              799 URN
            </div>
            <div className="headphone__model-img" style={{ background: '#F6F6F6' }}>
              <img src="./images/headphone-model-1.png" alt="" />
            </div>
            <div className="headphone__model-text">
              <p className="headphone__model-text-name">Модель 1</p>
              <p className="headphone__model-text-title">Новые наушники</p>
            </div>
            <div className="headphone__model-price">
              9 URN
            </div> */}
          </div>
          <div className="transfer">
            <div className="transfer__bank">
              <div className="transfer__bank-logo">
                <img src={bankValue.logo} height={20} alt="" />
              </div>
              <div className="transfer__bank-name">Перевод картой {bankValue.name}</div>
              <div className="transfer__bank-time">{<Timer seconds={merchant.time_window_sec} />}</div>
            </div>
            <p className="transfer-sum">Переведите<span>{merchant.value} <strong>₽</strong></span></p>
            <p className="transfer-telephone">На номер<span>{merchant?.merchant_account?.address}</span></p>
            <p className="transfer-bank-recipient">Банк получателя<span>{merchant.bank.name}</span></p>
            <p className="transfer-bank-recipient">Имя получателя<span>{merchant.merchant_account.fio}</span></p>
          </div>
          <div className="transfer__protected">
            <img src="./images/lock.svg" alt="" />
            Перевод защищён. <a href="#"> Подробнее</a>
          </div>
          <div className="block__instructions">
            <p><span>1.</span> Откройте приложение вашего банка</p>
            <p><span>2.</span> Выберите перевод через СБП или по номеру телефона</p>
            <p><span>3.</span> Переведите {merchant.value} Р в {merchant.bank.name}</p>
            <p><span>4.</span> Вернитесь и нажмите кнопку ниже</p>
          </div>
          <div className="checkbox__transfer">
            <input ref={confirmCheckbox} type="checkbox" id="transfer_in_one_amount" name="transfer_in_one_amount" />
            <label htmlFor="transfer_in_one_amount">Я сделал перевод (одной суммой)</label>
          </div>
          <button onClick={onConfirm} className="btn-gradient" style={{ marginBottom: '10px' }}>
            Подтверждаю
            <img src="./images/long-arrow.svg" alt="" />
          </button>
          <button className="btn-black" style={{ marginBottom: '26px' }}>Вернуться</button>
        </div>
        : null}
      {merchant && (merchant.status === 'checking' || merchant.status === 'approved') ?
        <div style={{ marginTop: '60px' }} className="chose__screen">
          <p className="screen__tetle" style={{ marginBottom: '112px' }}>Вы покупаете
            <img onClick={() => toMarketPlace()} src={Arrow} alt="" />
          </p>
          <div className="big-text">
            Партнер проверяет ваш перевод <span>{merchant.value} ₽</span>
          </div>
          <p className="chose__screen-text" style={{ marginTop: '15px' }}>Как только партнер подтвердит перевод, мы совершим вашу
            оплату</p>
          <div style={{
            background: '#F6F6F6',
            borderRadius: '20px',
            width: '235px',
            height: '70px',
            margin: '55px auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '30px',
          }}>
            <Timer seconds={merchant.time_window_sec} />
          </div>
          <p className="chose__screen-text">Обычно проверка занимает до 5 минут</p>
          <button className="btn-black" style={{ marginTop: '164px', marginBottom: '10px' }}>
            <img style={{ marginRight: '16px' }} src="./images/headphones.svg" alt="" />
            Поддержка
          </button>
          <button className="btn-black" style={{ marginBottom: '26px' }}>Вернуться</button>
        </div> : null}
    </div>
  );
}

export default Fiat;

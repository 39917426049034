import React, { useState, useEffect, useContext } from "react";
import creditCard from "../assets/images/Credit-card.svg";
import longArrow from "../assets/images/long-arrow.svg";
import Arrow from "../assets/images/arrow.svg";
import Wallet from "../assets/images/Wallet.svg";
import { useSearchParams, useNavigate } from "react-router-dom";
import { UserContext } from "./../App";

function Main () {
   const [cryptoW, setCryptoW] = useState("crypto");
   const navigate = useNavigate();
   const { user, setUser } = useContext(UserContext);
   const [backLoading, setBackLoading] = useState(false);
   const [searchParams] = useSearchParams();
   const token = searchParams.get("token");
   useEffect(() => {
      const requestOptions = {
         method: "GET",
         headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
         },
      };
      fetch("https://app.pubhause.com/api/profile/user-detail-web", requestOptions)
         .then((response) => response.json())
         .then((data) => {
            setUser(data?.data);
         });
   }, []);

   function next () {
      if (cryptoW === "crypto") return navigate(`/crypto?token=${token}`);
      if (cryptoW === "p2p") return navigate(`/fiat?token=${token}`);
   }

   function toMarketPlace () {
      setBackLoading(true);

      setTimeout(function () {
         setBackLoading(false);
      }, 3000);
      window.location.href = `https://app.pubhause.com/me/?token=${token}`;
   }

   return (
      <div className="App" style={{ paddingTop: "40px" }}>
         <div className="chose__screen">
            <p className="screen__tetle">Оплата</p>

            <div
               className={`screen__withdraw-profit ${cryptoW === "crypto" ? "withdraw-profit_active" : ""
                  }`}
               onClick={() => setCryptoW("crypto")}
            >
               {/* <img src={creditCard} className="App-logo" alt="logo" /> */}
               <p className="mt-2 screen__withdraw-profit-name">Крипто</p>
               <p className="screen__withdraw-profit-text">Оплатить криптовалютой</p>

               {cryptoW === "crypto" ? (
                  <div className="arrow-right">
                     <img src={Arrow} className="App-logo" alt="logo" />
                  </div>
               ) : null}
            </div>
            <div
               className={`screen__withdraw-profit ${cryptoW === "p2p" ? "withdraw-profit_active" : ""
                  }`}
               onClick={() => setCryptoW("p2p")}
            >
               {/* <img src={Wallet} className="App-logo" alt="logo" /> */}
               <p className="mt-2 screen__withdraw-profit-name">Фиат </p>
               <p className="screen__withdraw-profit-text">
                  Оплатить наушники картой вашего банка
               </p>
               {cryptoW === "p2p" ? (
                  <div className="arrow-right">
                     <img src={Arrow} className="App-logo" alt="logo" />
                  </div>
               ) : null}
            </div>

            {/* <div
               className={`screen__withdraw-profit ${cryptoW === "inner" ? "withdraw-profit_active" : ""
                  }`}
               onClick={() => setCryptoW("inner")}
            >
               <p className="mt-2 screen__withdraw-profit-name">Со счета</p>
               <p className="screen__withdraw-profit-text">Оплатить с прибыли</p>
               {cryptoW === "inner" ? (
                  <div className="arrow-right">
                     <img src={Arrow} className="App-logo" alt="logo" />
                  </div>
               ) : null}
            </div> */}
            <button className="btn-gradient mt-2" onClick={() => next()}>
               Далее&#8195;
               <img src={longArrow} className="App-logo" alt="logo" />
            </button>

            <button className="btn-black mt-1" onClick={() => toMarketPlace()}>
               {backLoading === true ? "Загрузка..." : "Вернуться"}
            </button>
         </div>
      </div>
   );
}

export default Main;
